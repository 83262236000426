import { Injectable, OnDestroy } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { SearchService } from '@app/shared/service/search/search.service';
import { NAV_DRAWER_ITEMS, RootNavigationRoute } from '@constants';
import { AccountStorage, BreakpointObserverService, FormConfirmSaveService } from '@core/service';
import { ParamsService } from '@core/service/params.service';
import { SessionStorage } from '@services/api';
import { FeatureEnableService } from '@services/shared';
import { NavDrawerItem, SidenavStateType } from '@typings';

import { RedirectService } from '../redirect.service';

type MoveHistoryItem = {
  navItem: NavDrawerItem;
  url: string;
};

@Injectable({
  providedIn: 'root',
})
export class SidenavService implements OnDestroy {
  #mobileSidenavOpen = new BehaviorSubject<boolean>(false);
  mobileSidenavOpen$ = this.#mobileSidenavOpen.asObservable();

  #expandedState = new BehaviorSubject<boolean>(false);
  expandedState$ = this.#expandedState.asObservable();

  #clickOpenState = new BehaviorSubject<boolean>(false);
  clickOpenState$ = this.#clickOpenState.asObservable();

  #currentNavItem: BehaviorSubject<NavDrawerItem>;
  currentNavItem$: Observable<NavDrawerItem>;

  currentSidebarNavItem$: Observable<NavDrawerItem>;

  #topBarItem: BehaviorSubject<NavDrawerItem>;
  topBarItem$: Observable<NavDrawerItem>;
  topBarCountItem$: BehaviorSubject<string>;

  sidenavState$: Observable<SidenavStateType>;

  paramMap: Map<string, string> = new Map<string, string>();
  #resetParamMap = false;

  #moveHistory: MoveHistoryItem[] = [];
  showConfirmDialogForcedOnNavigate = false;
  destroy$ = new Subject<void>();
  backToPreviousRoute = new Subject<void>();
  transparentTopBar$ = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private sessionStorage: SessionStorage,
    private bos: BreakpointObserverService,
    private formConfirmSaveService: FormConfirmSaveService,
    private features: FeatureEnableService,
    private paramsService: ParamsService,
    private accountStorage: AccountStorage,
    private redirectService: RedirectService,
    private searchService: SearchService,
  ) {
    const defaultNavItem = this.getDefaultNavItem();

    this.#currentNavItem = new BehaviorSubject<NavDrawerItem>(defaultNavItem);
    this.currentNavItem$ = this.#currentNavItem.asObservable();

    this.currentSidebarNavItem$ = this.currentNavItem$.pipe(
      map((navItem) => {
        return this.getSidebarItemFromNavItem(navItem) || navItem;
      }),
    );

    this.#topBarItem = new BehaviorSubject<NavDrawerItem>(defaultNavItem);
    this.topBarItem$ = this.#topBarItem.asObservable();

    this.topBarCountItem$ = new BehaviorSubject<string>('');
    this.searchService.openSearch$ = new BehaviorSubject<boolean>(false);

    this.sidenavState$ = combineLatest([
      this.bos.isMobile$,
      this.mobileSidenavOpen$,
      this.expandedState$,
      this.topBarItem$,
      this.clickOpenState$,
    ]).pipe(
      map(([isMobile, mobileSidenavOpen, isExpanded, topBarItem, _]) => {
        const isCompactView = this.sessionStorage.isCompactSidebarView();
        return {
          mode: this.getDrawerMode(isCompactView, isExpanded),
          isOpen: this.isOpen(isMobile, mobileSidenavOpen),
          isCompactView,
          isMobile,
          isCollapsed: this.isCollapsed(isCompactView, isExpanded),
          topBarIcon: topBarItem?.icon,
        };
      }),
    );

    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd || e instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((e) => {
        if (e instanceof ActivationEnd) {
          if (this.#resetParamMap) {
            this.paramMap.clear();
            this.#resetParamMap = false;
          }

          e.snapshot.paramMap.keys.forEach((key) => {
            this.paramMap.set(key, e.snapshot.paramMap.get(key) || '');
          });
        }

        if (e instanceof NavigationEnd) {
          this.#resetParamMap = true;
          this.initCurrentNavItemByRoute();
        }
      });
  }

  private isCollapsed(isCompactView: boolean, isExpanded: boolean): boolean {
    return isCompactView && !isExpanded;
  }

  private isOpen(isMobile: boolean, mobileSidenavOpen: boolean): boolean {
    return !isMobile || mobileSidenavOpen;
  }

  private getDrawerMode(isCompactView: boolean, isExpanded: boolean): MatDrawerMode {
    return !(isCompactView && isExpanded) ? 'side' : 'over';
  }

  getNavItem(): NavDrawerItem {
    return this.#currentNavItem.getValue();
  }

  setTopBarItem(item: NavDrawerItem): void {
    this.#topBarItem.next(item);
    this.searchService.resetSearch();
  }

  setTopBarTitle(title: string): void {
    this.#topBarItem.getValue().title = title;
  }

  public setMobileSidenavOpen(isOpen: boolean) {
    setTimeout(() => {
      this.#mobileSidenavOpen.next(isOpen);
    });
  }

  public setExpandedState(isExpanded: boolean): void {
    this.#expandedState.next(isExpanded);
  }

  public seClickOpenState(clickOpenState: boolean): void {
    this.#clickOpenState.next(clickOpenState);
  }

  public initCurrentNavItemByRoute(): void {
    const url = this.router.url;

    if (url.includes('setup')) {
      return;
    }

    let rawUrl = url.split('/').slice(2).join('/').split('?')[0];

    Array.from(this.paramMap.entries()).forEach(([key, value]) => {
      if (!value) {
        console.error(`No value for ${key} key`);
        return;
      }

      rawUrl = rawUrl.replace(value, key);
    });

    const navItem = this.getNavItemByRawUrl(rawUrl.split('/'), null);

    if (!navItem) {
      const dafaultNavItem = this.getDefaultNavItem();
      console.warn(`Default nav item ${dafaultNavItem.route} is set. Route is ${this.router.url}`);
      this.navigateToNavItem(this.getDefaultNavItem());
      return;
    }

    const currentItem = this.#currentNavItem.getValue();

    if (currentItem === navItem) {
      return;
    }

    this.#moveHistory.push({ navItem, url });
    this.#currentNavItem.next(navItem);
    this.#topBarItem.next(navItem);
    this.topBarCountItem$.next('');
  }

  clickTopBarButton() {
    const item = this.#topBarItem.getValue();

    if (item.icon === 'arrowLeft') {
      this.back();
    }

    if (item.icon === 'burgerMenu') {
      this.openSidenav();
    } else {
      this.backToPreviousRoute.next();
    }
  }

  back(shouldConfirm: boolean = true): void {
    const navItem = this.#currentNavItem.getValue();

    this.#moveHistory.pop(); // pop current nav item
    const prevNavItem = this.#moveHistory.pop()?.navItem;

    if (navItem.isStacking) {
      this.setNavItemConfirmSave(prevNavItem || this.getDefaultNavItem(), shouldConfirm, navItem.keepUrlParams);
      return;
    }

    const navItemWithParents = this.getNavItemWithParents(navItem);

    if (navItem.skipParent && navItemWithParents.length > 2) {
      this.setNavItemConfirmSave(navItemWithParents[navItemWithParents.length - 3], shouldConfirm, navItem.keepUrlParams);
      return;
    }

    if (navItemWithParents.length > 1) {
      this.setNavItemConfirmSave(navItemWithParents[navItemWithParents.length - 2], shouldConfirm, navItem.keepUrlParams);
    }
  }

  public openSidenav(): void {
    this.sessionStorage.setSidebarView('default');
    this.setMobileSidenavOpen(true);
  }

  private navigateToNavItem(navItem: NavDrawerItem, keepUrlParams?: boolean): void {
    const path = this.getPathFromNavItem(navItem);

    if (keepUrlParams) {
      this.router.navigate(path, { queryParams: this.paramsService.getRouterParams() });
    } else {
      this.router.navigate(path);
    }
  }

  private getNavItemByRawUrl(urlSegments: string[], currentNavItem: NavDrawerItem | null): NavDrawerItem | null {
    if (!urlSegments.length) {
      return currentNavItem;
    }

    const searchIn = currentNavItem ? currentNavItem.routes || [] : this.getNavItems();
    const newItem = searchIn.find((item) => {
      const itemRouteSegments = item.route?.split('/');

      if (!itemRouteSegments || urlSegments.length < itemRouteSegments.length) {
        return false;
      }

      const urlSegmentsJoined = urlSegments.slice(0, itemRouteSegments.length).join('/');

      return urlSegmentsJoined === item.route;
    });

    if (!newItem) {
      return currentNavItem;
    }

    const newItemUrlSegmentsLength = newItem.route?.split('/').length;

    return this.getNavItemByRawUrl(urlSegments.slice(newItemUrlSegmentsLength), newItem);
  }

  private getPathFromNavItem(item: NavDrawerItem): string[] {
    const path = this.getNavItemWithParents(item).flatMap((i) => (i.route ? i.route?.split('/') : ''));
    const replacements: Array<{ idx: number; str: string }> = [];

    Array.from(this.paramMap.entries()).forEach(([key, value]) => {
      const idx = path.findIndex((segment) => segment === key);

      if (idx !== -1 && value) {
        replacements.push({ idx, str: value });
      }
    });

    replacements.forEach(({ idx, str }) => {
      path[idx] = str;
    });

    const orgId = this.sessionStorage.getOrgId()!;

    return [orgId, ...path];
  }

  private getSidebarItemFromNavItem(navItem: NavDrawerItem): NavDrawerItem | null {
    if (navItem.isStacking) {
      if (navItem.substituteItem) {
        return navItem.substituteItem;
      }
      if (this.#moveHistory.length < 2) {
        return this.getDefaultNavItem();
      }

      return this.#moveHistory[this.#moveHistory.length - 2].navItem;
    }

    const navItemWithParents = this.getNavItemWithParents(navItem);

    if (navItemWithParents.length < 2) {
      return null;
    }

    if (navItemWithParents.length === 2) {
      return navItem;
    }

    return navItemWithParents[1];
  }

  private getNavItemWithParents(item: NavDrawerItem): NavDrawerItem[] {
    if (!item.parent) {
      return [item];
    }

    return [...this.getNavItemWithParents(item.parent), item];
  }

  public setNavItemConfirmSave(navItem: NavDrawerItem, shouldConfirm: boolean = true, keepUrlParams?: boolean): void {
    if (this.showConfirmDialogForcedOnNavigate) {
      this.setNavItemWithConfirmSaveForced(navItem, keepUrlParams);
      return;
    }

    this.formConfirmSaveService
      .closeForm(shouldConfirm)
      .pipe(take(1))
      .subscribe((res) => res && this.navigateToNavItem(navItem, keepUrlParams));
  }

  public setNavItemWithConfirmSaveForced(navItem: NavDrawerItem, keepUrlParams?: boolean): void {
    this.formConfirmSaveService
      .openDialog()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.navigateToNavItem(navItem, keepUrlParams);
        }
      });
  }

  public getNavItems(): NavDrawerItem[] {
    return NAV_DRAWER_ITEMS(this.features, this.accountStorage, false, this.accountStorage.hideStart$.getValue());
  }

  public getDefaultNavItem(): NavDrawerItem {
    const navItems = this.getNavItems();
    const analyticsItem = navItems.find((item) => item.route === RootNavigationRoute.analytics);
    if (!analyticsItem) {
      return navItems[0];
    }
    return analyticsItem;
  }

  public isNavItemsEqual(nav1: NavDrawerItem, nav2: NavDrawerItem): boolean {
    const path1 = this.getPathFromNavItem(nav1).join('/');
    const path2 = this.getPathFromNavItem(nav2).join('/');

    return path1 === path2;
  }

  openCatalogSettingsWithSection() {
    const orgId = this.sessionStorage.getOrgId();
    if (orgId) {
      this.redirectService.redirectToCatalogSettings(orgId);
      this.#topBarItem.next(this.#currentNavItem.getValue());
    }
  }
  isMobileSidenavOpen() {
    return this.#mobileSidenavOpen.getValue();
  }

  setTransparentTopBar(condition: boolean) {
    this.transparentTopBar$.next(condition);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
